<template>
  <div class="content">
    <PageHeader title="Gastos" />
    <div class="page-content container-fluid">
      <div class="row">
        <div class="col-lg-12 col-xl-12">
          <div class="card">
            <div class="card-header row">
              <div class="col-2">
                <span class="m-t-5 d-inline-block">Mês</span>
              </div>
              <div class="col-1">
                <select-input
                  v-model="date"
                  :options="months"
                  :reduce="months => months.date"
                  :clearable="false"
                  @input="fetchUsage"
                ></select-input>
              </div>
            </div>
            <div class="card-body block-el p-0">
              <div class="table-responsive">
                <div v-if="!usage.products && loaded" class="text-center table-placeholder">
                  <br>
                  <i class="fas fa-tachometer-alt font-size-80"></i>
                  <h5 class="card-title m-t-20">Nenhum gasto encontrado</h5>
                </div>
                <table v-if="loaded" class="table">
                  <thead class="bg-light">
                    <tr>
                      <th>{{$tc('generic-str.lbl-product', 1)}}</th>
                      <th>{{$t('generic-str.lbl-quantity')}}</th>
                      <th>{{$t('generic-str.lbl-cost')}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <b>Total</b>
                      </td>
                      <td>
                        <b>{{ usage.totalCount }}</b>
                      </td>
                      <td>
                        <b>{{ usage.totalPrice | currency }}</b>
                      </td>
                    </tr>
                    <tr v-for="product in usage.products" :key="product.name">
                      <td>{{ product.description }}</td>
                      <td>{{ product.count }}</td>
                      <td>{{ product.price | currency }}</td>
                    </tr>
                  </tbody>
                </table>
                <div v-else class="qt-block-ui" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import UsageService from '@/services/usage.service';
import PageHeader from '@/components/PageHeader.vue';
import SelectInput from '@/components/SelectInput.vue';

export default {
  name: 'Usage',
  components: {
    PageHeader,
    SelectInput,
  },
  data() {
    return {
      loaded: false,
      months: [],
      date: '',
      usage: [],
    };
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
  },
  created() {
    this.fetchMonths();
  },
  methods: {
    fetchMonths() {
      UsageService.getMonths().then((response) => {
        this.months = response;
        if (response.length > 0) {
          this.date = response[0].date;
        }
        this.fetchUsage();
      });
    },
    fetchUsage() {
      this.loaded = false;
      UsageService.getAllUsage(this.date).then((response) => {
        this.usage = response;
        this.loaded = true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.table-responsive {
  min-height: 150px;
}
</style>
